<template>
  <div class="bg-[#FCFCFD]"><router-view></router-view></div>
</template>
<style>
label,
.input-label {
  color: #344054 !important;
  font-size: 14px;
  margin-bottom: 6px;
}
</style>
