// esilnt-disable
import { createRouter, createWebHistory } from "vue-router";
import store from "../store";

const routes = [
  {
    path: "/test",
    name: "about",

    component: () =>
      import(/* webpackChunkName: "favourites" */ "../views/AboutView.vue"),
    meta: {
      title: "AboutView - Matta Trade",
      roles: [
        "superadmin",
        "superuser",
        "admin",
        "sales",
        "commercial",
        "finance",
        "agent",
      ],
      allowed: ["CompanyAdmin", "CompanyUser"],
      metaTags: [
        {
          name: "description",
          content: "AboutView  of Matta Trade.",
        },
        {
          property: "og:description",
          content: "AboutView  page Matta Trade.",
        },
      ],
      breadcrumbs: [],
    },
  },
  {
    path: "/cart",
    name: "shopping cart",

    component: () =>
      import(/* webpackChunkName: "shoppingcart" */ "../views/CartView.vue"),
    meta: {
      title: "Shopping cart - Matta Trade",
      roles: [
        "superadmin",
        "superuser",
        "admin",
        "sales",
        "commercial",
        "finance",
        "agent",
      ],
      allowed: ["CompanyAdmin", "CompanyUser"],
      metaTags: [
        {
          name: "description",
          content: "Shopping cart  of Matta Trade.",
        },
        {
          property: "og:description",
          content: "Shopping cart  page Matta Trade.",
        },
      ],
      breadcrumbs: [
        {
          name: "overview",
          url: "/dashboard",
        },
        {
          name: store.getters.loggedUser
            ? store.getters.loggedUser.fullName
            : "",
          url: "#",
        },
        {
          name: "Shopping cart",
          url: "#",
        },
      ],
      requiresAuth: true,
    },
  },
  {
    path: "/checkout/:id",
    name: "checkout",

    component: () =>
      import(/* webpackChunkName: "checkout" */ "../views/CheckoutView.vue"),
    meta: {
      title: "Checkout - Matta Trade",
      roles: [
        "superadmin",
        "superuser",
        "admin",
        "sales",
        "commercial",
        "finance",
        "agent",
      ],
      allowed: ["CompanyAdmin", "CompanyUser"],
      metaTags: [
        {
          name: "description",
          content: "Checkout  of Matta Trade.",
        },
        {
          property: "og:description",
          content: "Checkout  page Matta Trade.",
        },
      ],
      breadcrumbs: [
        {
          name: "overview",
          url: "/dashboard",
        },
        {
          name: "shopping cart",
          url: "/cart",
        },
        {
          name: "Checkout",
          url: "#",
        },
      ],
    },
  },
  {
    path: "/page/supplier/:name/:id",
    name: "supplier page",

    component: () =>
      import(
        /* webpackChunkName: "supplierpage" */ "../views/SupplierPage.vue"
      ),
    meta: {
      title: "Supplier Page - Matta Trade",
      roles: [
        "superadmin",
        "superuser",
        "admin",
        "sales",
        "commercial",
        "finance",
        "agent",
      ],
      allowed: ["CompanyAdmin", "CompanyUser"],
      metaTags: [
        {
          name: "description",
          content: "Supplier Page  of Matta Trade.",
        },
        {
          property: "og:description",
          content: "Supplier Page  page Matta Trade.",
        },
      ],
      breadcrumbs: [
        {
          name: "overview",
          url: "/dashboard",
        },

        {
          name: "Supplier",
          url: "#",
        },
      ],
      requiresAuth: false,
    },
  },

  {
    path: "/markets",
    name: "Markets",
    component: () =>
      import(/* webpackChunkName: "markets" */ "../views/CatalogsView.vue"),
    meta: {
      title: "Markets - Matta Trade",
      requiresAuth: true,
      roles: [
        "superadmin",
        "superuser",
        "admin",
        "sales",
        "commercial",
        "finance",
        "agent",
      ],
      allowed: ["CompanyAdmin", "CompanyUser"],
      metaTags: [
        {
          name: "description",
          content: "Markets page of Matta Trade.",
        },
        {
          property: "og:description",
          content: "Markets page Matta Trade.",
        },
      ],
    },
  },
  {
    path: "/applications",
    name: "Applications",
    component: () =>
      import(
        /* webpackChunkName: "applications" */ "../views/TechnologiesView.vue"
      ),
    meta: {
      title: "Applications - Matta Trade",
      requiresAuth: true,
      roles: [
        "superadmin",
        "superuser",
        "admin",
        "sales",
        "commercial",
        "finance",
        "agent",
      ],
      allowed: ["CompanyAdmin", "CompanyUser"],
      metaTags: [
        {
          name: "description",
          content: "Applications page of Matta Trade.",
        },
        {
          property: "og:description",
          content: "Applications page Matta Trade.",
        },
      ],
    },
  },
  {
    path: "/order-success",
    name: "Transaction successful",
    component: () =>
      import(
        /* webpackChunkName: "Transactionsuccessful" */ "../views/TransactionSuccessful.vue"
      ),
    meta: {
      requiresAuth: true,
      title: "Transaction successful - Matta Trade",
      roles: [
        "superadmin",
        "superuser",
        "admin",
        "sales",
        "commercial",
        "finance",
        "agent",
      ],
      allowed: ["CompanyAdmin", "CompanyUser"],
      metaTags: [
        {
          name: "description",
          content: "Transaction successful page of Matta Trade.",
        },
        {
          property: "og:description",
          content: "Transaction successful page Matta Trade.",
        },
      ],
    },
  },
  {
    path: "/suppliers",
    name: "Suppliers",
    component: () =>
      import(
        /* webpackChunkName: "allsuppliers" */ "../views/SuppliersView.vue"
      ),
    meta: {
      title: "Suppliers - Matta Trade",
      roles: [
        "superadmin",
        "superuser",
        "admin",
        "sales",
        "commercial",
        "finance",
        "agent",
      ],
      requiresAuth: true,
      metaTags: [
        {
          name: "description",
          content: "Suppliers page of Matta Trade.",
        },
        {
          property: "og:description",
          content: "Suppliers page Matta Trade.",
        },
      ],
    },
  },
  {
    path: "/search/product/:type",
    name: "Product search",
    component: () =>
      import(
        /* webpackChunkName: "searchproduct" */ "../views/ProductSearchView.vue"
      ),
    meta: {
      title: "Product search - Matta Trade",
      roles: [
        "superadmin",
        "superuser",
        "admin",
        "sales",
        "commercial",
        "finance",
        "agent",
      ],
      allowed: ["CompanyAdmin", "CompanyUser"],
      metaTags: [
        {
          name: "description",
          content: "Product search page of Matta Trade.",
        },
        {
          property: "og:description",
          content: "Product search page Matta Trade.",
        },
      ],
      breadcrumbs: [
        {
          name: "overview",
          url: "/dashboard",
        },
        {
          name: "Search",
          url: "#",
        },
      ],
    },
  },
  {
    path: "/:category/:type/:marketId",
    name: "Catalog",
    component: () =>
      import(/* webpackChunkName: "catalog" */ "../views/CatalogView.vue"),
    meta: {
      title: "Catalog - Matta Trade",
      requiresAuth: true,
      roles: [
        "superadmin",
        "superuser",
        "admin",
        "sales",
        "commercial",
        "finance",
        "agent",
      ],
      metaTags: [
        {
          name: "description",
          content: "Catalog page of Matta Trade.",
        },
        {
          property: "og:description",
          content: "Catalog page Matta Trade.",
        },
      ],
      breadcrumbs: [
        {
          name: "markets",
          url: "/markets",
        },
      ],
    },
  },
  {
    path: "/search/:category/:search_query",
    name: "Search Catalog",
    component: () =>
      import(
        /* webpackChunkName: "Searchcatalog" */ "../views/SearchCatalogView.vue"
      ),
    meta: {
      title: "Search Catalog - Matta Trade",
      requiresAuth: true,
      roles: [
        "superadmin",
        "superuser",
        "admin",
        "sales",
        "commercial",
        "finance",
        "agent",
      ],
      metaTags: [
        {
          name: "description",
          content: "Search Catalog page of Matta Trade.",
        },
        {
          property: "og:description",
          content: "Search Catalog page Matta Trade.",
        },
      ],
      breadcrumbs: [
        {
          name: "markets",
          url: "/markets",
        },
      ],
    },
  },
  {
    path: "/product/:type/:productId",
    name: "Product",
    component: () =>
      import(/* webpackChunkName: "product" */ "../views/ProductView.vue"),
    meta: {
      title: "Product - Matta Trade",
      roles: [
        "superadmin",
        "superuser",
        "admin",
        "sales",
        "commercial",
        "finance",
        "agent",
      ],
      requiresAuth: true,
      metaTags: [
        {
          name: "description",
          content: "Product page of Matta Trade.",
        },
        {
          property: "og:description",
          content: "Product page Matta Trade.",
        },
      ],
      breadcrumbs: [
        {
          name: "Markets",
          url: "/markets",
        },
      ],
    },
  },
  {
    path: "/login",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/auth/LoginView.vue"),
    meta: {
      title: "Login - Matta Trade",

      metaTags: [
        {
          name: "description",
          content: "Login in Matta account",
        },
        {
          property: "og:description",
          content: "Login in Matta account",
        },
      ],
    },
    beforeEnter: () => {
      if (store.getters.isLoggedIn) {
        return { name: "admin" };
      }
    },
  },
  {
    path: "/invited-user/:id",
    name: "register invited user",
    component: () =>
      import(
        /* webpackChunkName: "InvitedUser" */ "../views/auth/InvitedUser.vue"
      ),
    meta: {
      title: "Invited User - Matta Trade",
      metaTags: [
        {
          name: "description",
          content: "Invited User in Matta account",
        },
        {
          property: "og:description",
          content: "Invited User in Matta account",
        },
      ],
    },
    beforeEnter: () => {
      if (store.getters.isLoggedIn) {
        return { name: "admin" };
      }
    },
  },
  {
    path: "/register/:type",
    name: "register",
    component: () =>
      import(
        /* webpackChunkName: "Register" */ "../views/auth/RegisterView.vue"
      ),
    meta: {
      title: "Register - Matta Trade",
      metaTags: [
        {
          name: "description",
          content: "Register on matta",
        },
        {
          property: "og:description",
          content: "Register on matta",
        },
      ],
    },
    beforeEnter: () => {
      if (store.getters.isLoggedIn) {
        return { name: "admin" };
      }
    },
  },
  {
    path: "/select-role",
    name: "selectrole",
    component: () =>
      import(
        /* webpackChunkName: "RoleSelect" */ "../views/auth/RoleSelect.vue"
      ),
    meta: {
      title: "Role Select - Matta Trade",
      metaTags: [
        {
          name: "description",
          content: "Select a role",
        },
        {
          property: "og:description",
          content: "Select a role",
        },
      ],
    },
    beforeEnter: () => {
      if (store.getters.isLoggedIn) {
        return { name: "admin" };
      }
    },
  },

  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: () =>
      import(
        /* webpackChunkName: "ForgotPassword" */ "../views/auth/ForgotPassword.vue"
      ),
    meta: {
      title: "Forgot password - Matta Trade",
      metaTags: [
        {
          name: "description",
          content: "Forgot your pasword",
        },
        {
          property: "og:description",
          content: "Forgot your pasword",
        },
      ],
    },
    beforeEnter: () => {
      if (store.getters.isLoggedIn) {
        return { name: "admin" };
      }
    },
  },
  {
    path: "/resend-verification/:email",
    name: "ResendVerification",
    component: () =>
      import(
        /* webpackChunkName: "ForgotPassword" */ "../views/auth/ResendVerification.vue"
      ),
    meta: {
      title: "Resend Verification - Matta Trade",
      metaTags: [
        {
          name: "description",
          content: "Resend Verification",
        },
        {
          property: "og:description",
          content: "Resend Verification",
        },
      ],
    },
    beforeEnter: () => {
      if (store.getters.isLoggedIn) {
        return { name: "admin" };
      }
    },
  },
  {
    path: "/reset-password",
    name: "ResetPassword",
    component: () =>
      import(
        /* webpackChunkName: "ResetPassword" */ "../views/auth/ResetPassword.vue"
      ),
    meta: {
      title: "Reset Password - Matta Trade",
      metaTags: [
        {
          name: "description",
          content: "Reset your matta password ",
        },
        {
          property: "og:description",
          content: "Reset your matta password ",
        },
      ],
    },
    beforeEnter: () => {
      if (store.getters.isLoggedIn) {
        return { name: "admin" };
      }
    },
  },
  {
    path: "/password-recovery",
    name: "PasswordRecovery",
    component: () =>
      import(
        /* webpackChunkName: "PasswordRecovery" */ "../views/auth/PasswordRecovery.vue"
      ),
    meta: {
      title: "Password Recovery - Matta Trade",
      metaTags: [
        {
          name: "description",
          content: "Password Recovery success.",
        },
        {
          property: "og:description",
          content: "Password Recovery success.",
        },
      ],
    },
    beforeEnter: () => {
      if (store.getters.isLoggedIn) {
        return { name: "admin" };
      }
    },
  },

  {
    path: "/",
    name: "admin",
    redirect: "/dashboard",
    component: () =>
      import(/* webpackChunkName: "admin" */ "../views/AdminOverview.vue"),
    children: [
      {
        path: "dashboard",
        name: "storefront metrics",
        component: () =>
          import(
            /* webpackChunkName: "adminOverview" */ "@/components/admin/dashboard/index.vue"
          ),
        meta: {
          title: "Dashboard",
          roles: [
            "superadmin",
            "superuser",
            "admin",
            "sales",
            "commercial",
            "finance",
            "agent",
          ],
          metaTags: [
            {
              name: "description",
              content: "overview page of Matta Trade.",
            },
            {
              property: "og:description",
              content: "overview page Matta Trade.",
            },
          ],
          breadcrumbs: [
            {
              name: "Storefront",
              url: "#",
            },
            {
              name: "overview",
              url: "#",
            },
          ],
        },
      },
      {
        path: "metrics",
        name: "metrics",
        component: () =>
          import(
            /* webpackChunkName: "metrics" */ "@/components/admin/dashboard/Metrics.vue"
          ),
        meta: {
          title: "Dashboard",
          roles: [
            "superadmin",
            "superuser",
            "admin",
            "sales",
            "commercial",
            "finance",
            "agent",
          ],
          metaTags: [
            {
              name: "description",
              content: "metrics page of Matta Trade.",
            },
            {
              property: "og:description",
              content: "metrics page Matta Trade.",
            },
          ],
          breadcrumbs: [
            {
              name: "dashboard",
              url: "#",
            },
            {
              name: "metrics",
              url: "#",
            },
          ],
        },
      },
      {
        path: "settings",
        name: "admin settings",
        component: () =>
          import(
            /* webpackChunkName: "adminSettingsOverview" */ "@/components/admin/SettingsComponent.vue"
          ),
        meta: {
          title: "Settings",
          roles: [
            "superadmin",
            "superuser",
            "admin",
            "sales",
            "commercial",
            "finance",
            "agent",
          ],
          metaTags: [
            {
              name: "description",
              content: "Settings page of Matta Trade.",
            },
            {
              property: "og:description",
              content: "Settings page Matta Trade.",
            },
          ],
          breadcrumbs: [
            {
              name: "Account",
              url: "#",
            },
            {
              name: "Settings",
              url: "#",
            },
          ],
        },
      },
      {
        path: "administrators",
        name: "admin user management",
        component: () =>
          import(
            /* webpackChunkName: "Administrators" */ "@/components/admin/AdministratorsComponent.vue"
          ),
        meta: {
          title: "Administrators",
          roles: ["superadmin", "superuser", "admin"],
          allowed: ["CompanyAdmin"],
          metaTags: [
            {
              name: "description",
              content: "Administrators page of Matta Trade.",
            },
            {
              property: "og:description",
              content: "Administrators page Matta Trade.",
            },
          ],
          breadcrumbs: [
            {
              name: "overview",
              url: "/dashboard",
            },

            {
              name: "Administrators",
              url: "#",
            },
          ],
        },
      },
      {
        path: "market-settings",
        name: "market settings",
        component: () =>
          import(
            /* webpackChunkName: "MarketSettings" */ "@/views/MarketSettings.vue"
          ),
        meta: {
          title: "Market Settings",
          roles: ["superadmin", "superuser", "admin"],
          allowed: ["CompanyAdmin"],
          metaTags: [
            {
              name: "description",
              content: "Market Settings page of Matta Trade.",
            },
            {
              property: "og:description",
              content: "Market Settings page Matta Trade.",
            },
          ],
          breadcrumbs: [
            {
              name: "overview",
              url: "/dashboard",
            },

            {
              name: "Market Settings",
              url: "#",
            },
          ],
        },
      },

      {
        path: "customers",
        component: () =>
          import(
            /* webpackChunkName: "Administratorcustomers" */ "@/views/CustomersComponent.vue"
          ),
        children: [
          {
            path: "",
            name: "customers",
            component: () =>
              import(
                /* webpackChunkName: "AdministratorcustomersList" */ "@/components/admin/customer/CustomersComponent.vue"
              ),
            meta: {
              title: "Customers",
              roles: ["superadmin", "superuser", "admin"],
              allowed: ["CompanyAdmin"],
              metaTags: [
                {
                  name: "description",
                  content: "Customers page of Matta Trade.",
                },
                {
                  property: "og:description",
                  content: "Customers page Matta Trade.",
                },
              ],
              breadcrumbs: [
                {
                  name: "overview",
                  url: "/dashboard",
                },

                {
                  name: "Customers",
                  url: "#",
                },
              ],
            },
          },
          {
            path: "customer-settings/:name/:id/:role",
            name: "customer settings",
            component: () =>
              import(
                /* webpackChunkName: "customersettings" */ "@/components/admin/customer/CustomerSettings.vue"
              ),

            meta: {
              title: "Customer settings",
              roles: [
                "superadmin",
                "superuser",
                "admin",
                "sales",
                "commercial",
                "finance",
                "agent",
              ],
              metaTags: [
                {
                  name: "description",
                  content: "Customer settings page of Matta Trade.",
                },
                {
                  property: "og:description",
                  content: "Customer settings page Matta Trade.",
                },
              ],
              breadcrumbs: [
                {
                  name: "overview",
                  url: "/dashboard",
                },
                {
                  name: "customers",
                  url: "/customers",
                },
                {
                  name: "Customer settings",
                  url: "#",
                },
              ],
            },
          },
          {
            path: "customer/detail/:role/:id",
            name: "customer information",
            component: () =>
              import(
                /* webpackChunkName: "Administratorcustomerdetail" */ "@/components/admin/CustomerDetail.vue"
              ),
            meta: {
              title: "Customer information",
              roles: ["superadmin", "superuser", "admin"],
              allowed: ["CompanyAdmin"],
              metaTags: [
                {
                  name: "description",
                  content: "Customers information page of Matta Trade.",
                },
                {
                  property: "og:description",
                  content: "Customers information page Matta Trade.",
                },
              ],
              breadcrumbs: [
                {
                  name: "overview",
                  url: "/dashboard",
                },
                {
                  name: "Customers",
                  url: "/customers",
                },

                {
                  name: "Customer information",
                  url: "#",
                },
              ],
            },
          },
        ],
      },
      {
        path: "create-customer",
        name: "create customers",
        component: () =>
          import(
            /* webpackChunkName: "createcustomer" */ "@/components/admin/createcustomer/indexComponent.vue"
          ),
        meta: {
          title: "Create Customer",
          roles: ["superadmin", "superuser", "admin"],
          allowed: ["CompanyAdmin"],
          metaTags: [
            {
              name: "description",
              content: "Create Customer page of Matta Trade.",
            },
            {
              property: "og:description",
              content: "Create Customer page Matta Trade.",
            },
          ],
          breadcrumbs: [
            {
              name: "overview",
              url: "/dashboard",
            },
            {
              name: "customers",
              url: "/customers",
            },

            {
              name: "Create Customer",
              url: "#",
            },
          ],
        },
      },
      {
        path: "agents",
        component: () =>
          import(
            /* webpackChunkName: "Administratoragents" */ "@/views/AgentsComponent.vue"
          ),
        children: [
          {
            path: "",
            name: "agents",
            component: () =>
              import(
                /* webpackChunkName: "AdministratoragentList" */ "@/components/admin/agent/AgentsComponent.vue"
              ),
            meta: {
              title: "Agents",
              roles: ["superadmin", "superuser", "admin"],
              allowed: ["CompanyAdmin"],
              metaTags: [
                {
                  name: "description",
                  content: "Agents page of Matta Trade.",
                },
                {
                  property: "og:description",
                  content: "Agents page Matta Trade.",
                },
              ],
              breadcrumbs: [
                {
                  name: "overview",
                  url: "/dashboard",
                },

                {
                  name: "Agents",
                  url: "#",
                },
              ],
            },
          },
        ],
      },
      {
        path: "wallets",
        component: () =>
          import(
            /* webpackChunkName: "Administratorwallets" */ "@/views/WalletsComponent.vue"
          ),
        children: [
          {
            path: "",
            name: "wallets",
            component: () =>
              import(
                /* webpackChunkName: "AdministratorwalletsList" */ "@/components/admin/wallets/WalletsComponent.vue"
              ),
            meta: {
              title: "Wallets",
              roles: ["superadmin", "superuser", "admin"],
              allowed: ["CompanyAdmin"],
              metaTags: [
                {
                  name: "description",
                  content: "Wallets page of Matta Trade.",
                },
                {
                  property: "og:description",
                  content: "Wallets page Matta Trade.",
                },
              ],
              breadcrumbs: [
                {
                  name: "overview",
                  url: "/dashboard",
                },

                {
                  name: "Agents",
                  url: "#",
                },
              ],
            },
          },
          {
            path: "/wallets/wallet-transactions",
            name: "wallet transactions",
            component: () =>
              import(
                /* webpackChunkName: "customersettings" */ "@/components/admin/wallets/WalletTransaction.vue"
              ),

            meta: {
              title: "Wallet transaction",
              roles: [
                "superadmin",
                "superuser",
                "admin",
                "sales",
                "commercial",
                "finance",
                "agent",
              ],
              metaTags: [
                {
                  name: "description",
                  content: "Customer settings page of Matta Trade.",
                },
                {
                  property: "og:description",
                  content: "Customer settings page Matta Trade.",
                },
              ],
              breadcrumbs: [
                {
                  name: "overview",
                  url: "/dashboard",
                },
                {
                  name: "wallets",
                  url: "/wallets",
                },
                {
                  name: new URL(window.location.toString()).searchParams.get(
                    "customerName"
                  ),
                  url: "#",
                },
              ],
            },
          },
        ],
      },

      {
        path: "agents/detail",
        name: "Agent information",
        component: () =>
          import(
            /* webpackChunkName: "Administratorcustomerdetail" */ "@/components/admin/agent/AgentInformation.vue"
          ),
        meta: {
          title: "Agent information",
          roles: ["superadmin", "superuser", "admin"],
          allowed: ["CompanyAdmin"],
          metaTags: [
            {
              name: "description",
              content: "Agent information page of Matta Trade.",
            },
            {
              property: "og:description",
              content: "Agent information page Matta Trade.",
            },
          ],
          breadcrumbs: [
            {
              name: "overview",
              url: "/dashboard",
            },
            {
              name: "Agents",
              url: "/agents",
            },

            {
              name: new URL(window.location.toString()).searchParams.get(
                "agentName"
              ),
              url: "#",
            },
          ],
        },
      },
      {
        path: "agents/customer",
        name: "Agent customers",
        component: () =>
          import(
            /* webpackChunkName: "createcustomer" */ "@/components/admin/agent/AgentCustomerOrders.vue"
          ),
        meta: {
          title: "Agent Customer Orders",
          roles: ["superadmin", "superuser", "admin"],
          allowed: ["CompanyAdmin"],
          metaTags: [
            {
              name: "description",
              content: "Agent's customer orders page of Matta Trade.",
            },
            {
              property: "og:description",
              content: "Agent's customer orders page Matta Trade.",
            },
          ],
          breadcrumbs: [
            {
              name: "overview",
              url: "/dashboard",
            },
            {
              name: "agents",
              url: "/agents",
            },

            {
              name: "Agent customer orders",
              url: "",
            },
          ],
        },
      },
      {
        path: "notifications",
        name: "admin notifications",
        component: () =>
          import(
            /* webpackChunkName: "adminnotifications" */ "@/components/admin/NotificationComponent.vue"
          ),
        meta: {
          title: "Notifications",
          roles: [
            "superadmin",
            "superuser",
            "admin",
            "sales",
            "commercial",
            "finance",
            "agent",
          ],
          metaTags: [
            {
              name: "description",
              content: "Notifications page of Matta Trade.",
            },
            {
              property: "og:description",
              content: "Notifications page Matta Trade.",
            },
          ],
          breadcrumbs: [
            {
              name: "Account",
              url: "#",
            },
            {
              name: "notifications",
              url: "#",
            },
          ],
        },
      },

      {
        path: "products",
        component: () =>
          import(
            /* webpackChunkName: "Adminproductsview" */ "../views/AdminStorefrontProducts.vue"
          ),
        meta: {
          title: "Products",
          roles: [
            "superadmin",
            "superuser",
            "admin",
            "sales",
            "commercial",
            "finance",
            "agent",
          ],
          allowed: ["CompanyAdmin"],
        },
        children: [
          {
            path: "",
            name: "admin products",
            component: () =>
              import(
                /* webpackChunkName: "Accountproducts" */ "@/components/admin/ProductsComponent.vue"
              ),
            meta: {
              title: "Products",
              roles: ["supplier"],
              allowed: ["CompanyAdmin"],
              metaTags: [
                {
                  name: "description",
                  content: "Account products page of Matta Trade.",
                },
                {
                  property: "og:description",
                  content: "Account products page Matta Trade.",
                },
              ],
              breadcrumbs: [
                {
                  name: "overview",
                  url: "/dashboard",
                },
                {
                  name: "products",
                  url: "#",
                },
              ],
            },
          },
          {
            path: ":action/:SellerId",
            name: "admin add a new product",
            component: () =>
              import(
                /* webpackChunkName: "Addnewproduct" */ "@/components/admin/addproduct/IndexComponent.vue"
              ),
            meta: {
              title: "Add/Edit product",
              roles: ["supplier"],
              allowed: ["CompanyAdmin"],
              metaTags: [
                {
                  name: "description",
                  content: "Add/Edit  product page of Matta Trade.",
                },
                {
                  property: "og:description",
                  content: "Add/Edit  product page Matta Trade.",
                },
              ],
              breadcrumbs: [
                {
                  name: "overview",
                  url: "/dashboard",
                },
                {
                  name: "products",
                  url: "/products",
                },
                {
                  name: "add a new products",
                  url: "#",
                },
              ],
            },
          },
        ],
      },

      {
        path: "orders",
        name: "Orders",
        component: () =>
          import(
            /* webpackChunkName: "Adminorders" */ "@/components/admin/OrdersComponent.vue"
          ),
        meta: {
          title: "Orders",
          roles: [
            "superadmin",
            "superuser",
            "admin",
            "sales",
            "commercial",
            "finance",
            "agent",
          ],
          metaTags: [
            {
              name: "description",
              content: "Orders page of Matta Trade.",
            },
            {
              property: "og:description",
              content: "Orders page Matta Trade.",
            },
          ],
          breadcrumbs: [
            {
              name: "overview",
              url: "/dashboard",
            },
            {
              name: "orders",
              url: "#",
            },
          ],
        },
      },
      {
        path: "shipping-management",
        name: "Shipping Management",
        component: () =>
          import(
            /* webpackChunkName: "ShippingManagement" */ "@/components/admin/ShippingManagement.vue"
          ),
        meta: {
          title: "Shipping Management",
          roles: [
            "superadmin",
            "superuser",
            "admin",
            "sales",
            "commercial",
            "finance",
            "agent",
          ],
          metaTags: [
            {
              name: "description",
              content: "Shipping Management page of Matta Trade.",
            },
            {
              property: "og:description",
              content: "Shipping Management page Matta Trade.",
            },
          ],
          breadcrumbs: [
            {
              name: "overview",
              url: "/dashboard",
            },
            {
              name: "Shipping Management",
              url: "#",
            },
          ],
        },
      },
      {
        path: "financing",
        name: "Financing",
        component: () =>
          import(
            /* webpackChunkName: "Financing" */ "@/views/FinancingRequests.vue"
          ),
        children: [
          {
            path: "",
            name: "Financing requests",
            component: () =>
              import(
                /* webpackChunkName: "FinancingRequests" */ "@/components/admin/FinanceRequest/RequestsTable.vue"
              ),
          },
          {
            path: "request/:type/:id",
            name: "Financing request ",
            component: () =>
              import(
                /* webpackChunkName: "RequestFinancing" */ "@/components/admin/FinanceRequest/Form/indexFinance.vue"
              ),
          },
          {
            path: "requests/:type/:id",
            name: "Financing requests details",
            component: () =>
              import(
                /* webpackChunkName: "FinancingRequest" */ "@/components/admin/FinanceRequest/Trade/indexFinance.vue"
              ),
          },
          {
            path: "transactions/:name/:id",
            name: "Financing transactions",
            component: () =>
              import(
                /* webpackChunkName: "TransactionsFinancingRequest" */ "@/components/admin/FinanceRequest/TransactionHistory.vue"
              ),
          },
        ],
        meta: {
          title: "Financing",
          roles: [
            "superadmin",
            "superuser",
            "admin",
            "sales",
            "commercial",
            "finance",
            "agent",
          ],
          metaTags: [
            {
              name: "description",
              content: "Financing page of Matta Trade.",
            },
            {
              property: "og:description",
              content: "Financing page Matta Trade.",
            },
          ],
          breadcrumbs: [
            {
              name: "overview",
              url: "/dashboard",
            },
            {
              name: "Financing",
              url: "#",
            },
          ],
        },
      },
      {
        path: "credit",
        name: "credit",
        component: () =>
          import(
            /* webpackChunkName: "credit" */ "@/views/FinancingRequests.vue"
          ),
        children: [
          {
            path: ":type/:businessId/:id",
            name: "Credit requests details",
            component: () =>
              import(
                /* webpackChunkName: "creditrequest" */ "@/components/admin/FinanceRequest/Trade/indexFinance.vue"
              ),
          },
        ],
        meta: {
          title: "Credit Detail",
          roles: [
            "superadmin",
            "superuser",
            "admin",
            "sales",
            "commercial",
            "finance",
            "agent",
          ],
          metaTags: [
            {
              name: "description",
              content: "Financing page of Matta Trade.",
            },
            {
              property: "og:description",
              content: "Financing page Matta Trade.",
            },
          ],
          breadcrumbs: [
            {
              name: "overview",
              url: "/dashboard",
            },
            {
              name: "Financing",
              url: "#",
            },
          ],
        },
      },
      {
        path: "/payment-management",
        name: "Payouts & Settlements",
        component: () => import("@/views/PaymentManagement.vue"),
        meta: {
          title: "Payouts & Settlements",
          roles: [
            "superadmin",
            "superuser",
            "admin",
            "sales",
            "commercial",
            "finance",
            "agent",
          ],
          metaTags: [
            {
              name: "description",
              content: "Payouts & Settlements page of Matta Trade.",
            },
            {
              property: "og:description",
              content: "Payouts & Settlements page Matta Trade.",
            },
          ],
          breadcrumbs: [
            {
              name: "overview",
              url: "/dashboard",
            },
            {
              name: "Payouts & Settlements",
              url: "/payment-management", // Correct breadcrumb URL
            },
          ],
        },
        children: [
          {
            path: "",
            name: "Payouts & Settlements Index",
            component: () =>
              import("@/components/admin/PaymentManagement/IndexComponent.vue"),
          },
        ],
      },
      {
        path: "/campaign",
        name: "Campaign",
        component: () => import("@/views/Campaign.vue"),
        meta: {
          title: "Campaign",
          roles: [
            "superadmin",
            "superuser",
            "admin",
            "sales",
            "commercial",
            "finance",
            "agent",
          ],
          metaTags: [
            {
              name: "description",
              content: "Campaign page of Matta Trade.",
            },
            {
              property: "og:description",
              content: "Campaign page Matta Trade.",
            },
          ],
          breadcrumbs: [
            {
              name: "overview",
              url: "/dashboard",
            },
            {
              name: "Campaign",
              url: "/campaign", // Correct breadcrumb URL
            },
          ],
        },
        children: [
          {
            path: "",
            name: "Campaign Index",
            component: () => import("@/components/admin/campaign/index.vue"),
          },
          {
            path: "transactions/:id",
            name: "Campaign Transaction Details",
            component: () =>
              import("@/components/admin/campaign/transactions/index.vue"),
          },
          {
            path: "add",
            name: "Campaign Add",
            component: () => import("@/components/admin/campaign/new.vue"),
          },
          {
            path: "edit/:id",
            name: "Campaign Edit",
            component: () => import("@/components/admin/campaign/new.vue"),
          },
        ],
      },
      {
        path: "logs",
        name: "Logs my Requests",
        component: () =>
          import(
            /* webpackChunkName: "Logs" */ "@/components/admin/LogsComponent.vue"
          ),
        meta: {
          title: "Logs",
          roles: ["superadmin", "superuser", "admin"],
          allowed: ["CompanyAdmin", "CompanyUser"],
          metaTags: [
            {
              name: "description",
              content: "Logs page of Matta Trade.",
            },
            {
              property: "og:description",
              content: "Logs page Matta Trade.",
            },
          ],
          breadcrumbs: [
            {
              name: "overview",
              url: "/dashboard",
            },

            {
              name: "Logs",
              url: "#",
            },
          ],
        },
      },
      {
        path: "requests",
        name: "admin StoreRequests",
        component: () =>
          import(
            /* webpackChunkName: "adminstorerequests" */ "@/components/admin/StoreRequests.vue"
          ),
        meta: {
          title: "Requests",
          roles: [
            "superadmin",
            "superuser",
            "admin",
            "sales",
            "commercial",
            "finance",
            "agent",
          ],
          metaTags: [
            {
              name: "description",
              content: "Account requests page of Matta Trade.",
            },
            {
              property: "og:description",
              content: "Account requests page Matta Trade.",
            },
          ],
          breadcrumbs: [
            {
              name: "Overview",
              url: "/dashboard",
            },

            {
              name: "requests",
              url: "#",
            },
          ],
        },
      },
      {
        path: "procurement/my-requests",
        name: "admin my Requests",
        component: () =>
          import(
            /* webpackChunkName: "adminMyrequests" */ "@/components/admin/MyRequests.vue"
          ),
        meta: {
          title: "My requests",
          roles: [
            "superadmin",
            "superuser",
            "admin",
            "sales",
            "commercial",
            "finance",
            "agent",
          ],
          allowed: ["CompanyAdmin", "CompanyUser"],
          metaTags: [
            {
              name: "description",
              content: "Account requests page of Matta Trade.",
            },
            {
              property: "og:description",
              content: "Account requests page Matta Trade.",
            },
          ],
          breadcrumbs: [
            {
              name: "overview",
              url: "/dashboard",
            },
            {
              name: "procurement",
              url: "/procurement/my-orders",
            },
            {
              name: "my requests",
              url: "#",
            },
          ],
        },
      },
      {
        path: "procurement/my-orders",
        name: "admin my orders",
        component: () =>
          import(
            /* webpackChunkName: "adminMyOrders" */ "@/components/admin/MyOrders.vue"
          ),
        meta: {
          title: "My Orders",
          roles: [
            "superadmin",
            "superuser",
            "admin",
            "sales",
            "commercial",
            "finance",
            "agent",
          ],
          allowed: ["CompanyAdmin", "CompanyUser"],
          metaTags: [
            {
              name: "description",
              content: "My orders of Matta Trade.",
            },
            {
              property: "og:description",
              content: "My orders page Matta Trade.",
            },
          ],
          breadcrumbs: [
            {
              name: "overview",
              url: "/dashboard",
            },
            {
              name: "procurement",
              url: "/procurement/my-orders",
            },
            {
              name: "my orders",
              url: "#",
            },
          ],
        },
      },
      {
        path: "settings/markets",
        name: "Market settings",
        component: () =>
          import(
            /* webpackChunkName: "adminMyOrders" */ "@/components/admin/marketSettings/marketSetting.vue"
          ),
        meta: {
          title: "Market setting",
          roles: [
            "superadmin",
            "superuser",
            "admin",
            "sales",
            "commercial",
            "finance",
            "agent",
          ],
          allowed: ["CompanyAdmin", "CompanyUser"],
          metaTags: [
            {
              name: "description",
              content: "Market settings of Matta Trade.",
            },
            {
              property: "og:description",
              content: "Market setting page Matta Trade.",
            },
          ],
          breadcrumbs: [
            {
              name: "overview",
              url: "/dashboard",
            },
            {
              name: "settings",
              url: "#",
            },
            {
              name: "market",
              url: "#",
            },
          ],
        },
      },
      {
        path: "settings/applications",
        name: "applications",
        component: () =>
          import(
            /* webpackChunkName: "applications" */ "@/components/admin/marketSettings/technologySetting.vue"
          ),
        meta: {
          title: "Application setting",
          roles: [
            "superadmin",
            "superuser",
            "admin",
            "sales",
            "commercial",
            "finance",
            "agent",
          ],
          allowed: ["CompanyAdmin", "CompanyUser"],
          metaTags: [
            {
              name: "description",
              content: "Application settings of Matta Trade.",
            },
            {
              property: "og:description",
              content: "Application setting page Matta Trade.",
            },
          ],
          breadcrumbs: [
            {
              name: "overview",
              url: "/dashboard",
            },
            {
              name: "settings",
              url: "#",
            },
            {
              name: "applications",
              url: "#",
            },
          ],
        },
      },
    ],
    meta: {
      title: "Admin Dashboard - Matta Trade",
      metaTags: [
        {
          name: "description",
          content: "Admin Dashboard page of Matta Trade.",
        },
        {
          property: "og:description",
          content: "Admin Dashboard page Matta Trade.",
        },
      ],
      requiresAuth: true,
      isAdminRoute: true,
    },
  },
  {
    path: "/:catchAll(.*)",
    name: "Not found",
    component: () =>
      import(/* webpackChunkName: "notfound" */ "../views/NotFound.vue"),
  },
];
const scrollBehavior = (to, from, savedPosition) => {
  return savedPosition || { top: 0, left: 0 };
};
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior,
});

export default router;
